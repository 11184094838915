<template lang="html">
    <main-layout>
        <template #content>
            <profile-form-layout
                :show-modal="showModal"
                :title="'Phone'"
                @close-modal="showModal=false"
                @confirm="confirm"
            >
                <template #headerIcon>
                    <phone />
                </template>
                <template #form>
                    <div class="profile-info__form profile-info__form-phone">
                        <div class="profile-info__form-group">
                            <div class="profile-info__form-row">
                                <div
                                    class="profile-info__col60 phone-setting profile-info__col100--sm"
                                    style="margin: auto"
                                >
                                    <phone-number
                                        @phoneValidated="validatePhone"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </profile-form-layout>
        </template>
    </main-layout>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/phone/Rules.js'
import { formMessages } from '@/validation/phone/Messages.js'

import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
import MainLayout from '@/layouts/MainLayout';
import ProfileFormLayout from '@/layouts/profile/ProfileFormLayout';
import Phone from 'mdi-vue/Phone'

import { createNamespacedHelpers, mapState } from 'vuex';
import {
    ACTION_WRITER_UPDATE_PHONE
} from '@/store/modules/writer/action-types';
import {
    UPDATE_WRITER_PHONE_DETAILS
} from '@/store/modules/writer/mutation-types';
import { mapPhoneFields } from '@/store/modules/phone'

const {
    mapActions: mapWriterActions,
    mapMutations: mapWriterMutations
} = createNamespacedHelpers('writer');

export default {
    components: {
        PhoneNumber,
        MainLayout,
        ProfileFormLayout,
        Phone
    },
    metaInfo: {
        title: 'Phone'
    },
    validations: {
        ...validationRules
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    data() {
        return {
            showModal: false,
            phoneValidationStatus: false
        }
    },
    computed: {
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ])
    },
    watch: {
        verified(status) {
            if (status) {
                this.confirm()
                // this.showModal = true
            }
        }
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    mounted() {
        this.$store.commit('phone/SET_DEFAULT')
        eventBus.$on('validate', () => {
            this.check()
        })
    },
    methods: {
        ...mapWriterActions({
            updatePhone: ACTION_WRITER_UPDATE_PHONE
        }),
        ...mapWriterMutations([
            UPDATE_WRITER_PHONE_DETAILS
        ]),
        validationMessage: validationMessage(formMessages),
        validatePhone(value) {
            this.phoneValidationStatus = value
        },
        async confirm() {
            try {
                await this.updatePhone({
                    phone_code: this.country.value,
                    phone: this.phone_number
                })
                eventBus.$emit('showSnackBar', 'Your phone has been changed', 'success');
                await this.$router.push({ name: 'profile' })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
                console.error(err)
            }
        },
        check() {
            if (!this.phoneValidationStatus) {
                this.validate()
                return
            }
            this.showModal = true
        }
    }
}
</script>

<style lang="scss">
    @media screen and (max-width: 576px){
        .phone__row {
            .form-select {
                width: 30%;
                min-width: 30%;
            }
            .form-input__fieldset {
                width: 70%;
            }
            &-verify {
                margin-top: 24px;
            }
            .phone-verification__btn {
                margin-bottom: 0;
            }
        }
    }

    .profile-info__form-phone {
        margin-bottom: 30px !important;
    }
</style>
